import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "./boton/ButtonContent";



function BloqueHome( image1) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div className="pic-wrapper pb-10" style={{
      backgroundImage: `url("${rpdata?.stock?.[12]}")`,
      backgroundSize:"cover"
    }}>
      <div className="z-10 relative md:pt-[250px] pt-[200px] pb-[120px] md:w-4/5 w-full mx-auto flex md:flex-row flex-col p-6">
        <div className="md:w-1/2 h-full w-full md:text-start text-center flex items-center">
          <div className="w-full h-full">
            <h1 className=" md:text-start text-center text-white">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <ButtonContent btnStyle="three" />
          </div>
        </div>
        <div className="md:w-1/2 w-full p-4 text-center">
          <div className="bg-header md:w-full md:h-[800px] w-full md:mt-[-270px] md:mb-[-220px] md:ml-[300px]">
            <div className="w-full h-full flex justify-center align-middle items-center pt-0 md:pt-[120px]">
              <img
                src={rpdata?.stock?.[8]}
                alt="Not Found"
                className=" border-white border-[8px] mx-auto rounded-full md:w-[500px] md:h-[500px] sm:w-[600px] sm:h-[600px] w-[350px] h-[350px] mt-[35px]  object-cover md:mr-[500px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BloqueHome;
